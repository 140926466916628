import Api from '@/services/Index';

const getAll = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/exam/schedules/observer');
}

export default {
    getAll
}
